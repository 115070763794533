@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;700&family=Arvo:wght@400;700&family=Exo:wght@300;400;700&display=swap);
html {
  /* background: url('./assets/background/wave-bg.jpg') no-repeat center center fixed; */
  /* background-repeat: no-repeat; */
  /* background-position: fixed; */
  /* background-size: cover; */
  background: #182024;
  /* background: linear-gradient(90deg, rgba(160,60,182,1) 0%, rgba(38,77,94,1) 50%, rgba(24,32,36,1) 100%); */
  /* background: linear-gradient(90deg, rgb(71, 23, 82) 0%, rgb(14, 34, 48) 60%, rgba(24,32,36,1) 100%); */



}

body {
  margin: 0;
  font-family:
  'Exo',
  /* 'Dosis', */
  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgba(90,60,100, 0.55); */
  /* background-color: rgba(60,50,70, 0.75); */
}

html.noscroll {
  height: 100%;
  overflow: hidden;
  /* overflow-y: auto; */
}

body.noscroll {
  margin: 0px;
  padding: 0px;
  width: 100%;
  /* height: 100%; */
  position: relative;
  /* overflow-y: auto; */
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
color_palette {
  color1: #0b132b;
  color2: #1c2541;
  color3: #3a506b;
  color4: #5bc0be;
  color5: #6FFFE9;
}

a {
  color: white;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: white;
  width: 100%;
}

.App-main {
  /* padding: 0% 5%; */
  /* width: 90vw; */
  width: 90%;
  max-width: 90%;
  /* padding-top: 60px; */
  /* min-height: 100vh; */
  min-height: calc(100vh - 114px);
  margin: 60px 0px 0px;
}

.App-main-title {
  font-size: 2em;
  margin: 0px;
  
  /* border-bottom: solid; */
  /* border-color: #6FFFE9; */
  /* border-width: 1px; */
}

#overlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0,0,0, 0.7);
  opacity: 0;
  z-index: 100;
  visibility: hidden;
  transition: 0.3s linear;
}

#overlay.noscroll {
  opacity: 1;
  visibility: visible;
}

.project-card {
  background-color: rgba(0,0,0, 0.7);
  /* padding: 30px; */
  margin: 40px 0px;
  /* border-top: solid; */
  /* border-bottom: solid; */
  border-width: 3px;
  border-color: #5bc0be;
  text-align: left;
  display: block;
}

.project-card > div {
  /* display: block; */
  display: inline-block;
  /* margin: 0px 20px; */
}

.project-thumb {
  /* width: 80%; */
  /* flex-basis: 400px; */
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(90vw - (90vw - 100%));

  height: 50.625vw;

  /* margin: auto; */
  
}

.project-thumb img {
  width: 100%;
  height: 100%;
}

.project-description {
  text-align: left;
  padding: 0px 18px;
  /* display: flex; */
  /* width: 200px; */
}

.project-github-link {
  /* display: inline-block; */
}

.project-links p {
  margin: 0px 10px 16px 0px;
  display: inline-block;
  text-decoration: underline;
  transition: 0.4s;
}

.project-links p:hover {
  background-color: #0f6e75;
}

.video-wrapper {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
  overflow:hidden;
  margin: auto;
}

.video-wrapper iframe, .video-wrapper object, .video-wrapper embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.footer {
  font-size: 0.6em;
}




@media (min-width: 1200px) {
  .App-main {
    max-width: 1100px;
  }
}

@media (min-width: 950px) {
  .project-card {
    display: flex;
  }
  .project-thumb {
    width: 420px;
    height: 236px;
  }
}

@media (min-width: 720px) {
  .App-main {
    min-height: calc(100vh - 124px);
  }
  .App-main-title {
    font-size: 3em;
    margin: 0.67em 0em;
  }
  .burger-menu {
    display: none;
  }
  .footer {
    font-size: 0.8em;
  }
}
.navbar-wrapper {
  width: 100%;
  background-color: #182024;
  height: 4em;
  border-bottom: solid;
  border-width: 1px;
  border-color: #6FFFE9;
  /* justify-content: center; */
  /* align-items: center; */
  position: fixed;
  z-index: 10;
}

.navbar {
  /* display: inline-block; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* white-space: nowrap; */
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: auto;
  /* position: fixed; */
  top: 0px;
  background-color: #182024;

  /* box-shadow: 0px 0px 10000px #5bc0be */
}

.navbar-left {
  height: 100%;
  display: flex;
  float: left;
  align-items: center;
}

.navbar-name {
  /* margin: auto; */
  display: inline-flex;
  align-items: center;
  margin: 0 12px;
  /* padding: 0 6px; */
}

.navbar-name h1 {
  white-space: nowrap;
  font-family: 'dosis', sans-serif;
  font-size: 40px;
  margin: 0 6px;

}

.navbar-portrait {
  height: 2em;
  border-style: solid;
  border-radius: 2em;
}

.navbar-left a:hover {
  background-color: #0f6e75;
}

.navbar-left a {
  text-decoration: none;
  transition: 0.4s;
}

.navbar-right {
  /* height: 100%; */
  height: 64px;
  /* position: absolute; */
  /* right: 0px; */
  float: right;
  display: flex;
  /* justify-content: center; */
}

.navlink-wrapper {
  margin: auto;
  /* display: flex; */
  width: 480px;
  /* height: 100%; */
  height: 80%;
  z-index: 2;
  /* align-items: center; */
  /* justify-content: center; */
}

.navlink-wrapper a {
  /* width: 50%; */
  /* padding: 12px; */
  /* margin: 0 12px 0px; */
  margin: auto;
  /* width: 120px; */
  width: 25%;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.navlink {
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  /* font-weight: bold; */
  transition: 0.4s;
}

.navlink:hover {
  background-color: #0f6e75;
}

.media-icons {
  white-space: nowrap;
  margin: auto;
  /* display: flex; */
  /* float: right; */
  /* height: 100%; */
}

.media-icons a {
  /* height: 32px; */
  display: inline-block;
  height: 100%;
  /* color: white; */
  text-decoration: none;
  transition: 0.4s;
}

.media-icons a:visited {
  /* color: white; */
}

.media-icons a:hover {
  color: #0f6e75;
}

.media-icons svg {
  margin: 0px 8px;
}

@media (max-width: 950px) {
  .navbar-name h1 {
    font-size: 24px;
  }
  .navlink-wrapper {
    /* display: none; */
    width: 360px;
  }
}

@media (max-width: 720px) {
  .navbar-wrapper {
    height: 40px;
  }
  .navbar-name h1 {
    font-size: 24px;
  }
  .navbar-portrait {
    height: 1.2em;
    border-width: 1px;
  }
  .navbar-right {
    display: none;
  }
}
.home-wrapper {
  width: 100%;
  /* width: 100vw; */
  /* max-width: 1100px; */
  /* padding: 0px; */
  margin-top: 40px;
}

.App-main.home{
  min-height: 0px;
}

.home {
  margin: auto;
}

.home-header {
  width: 100%;
  /* max-width: 1200px; */
  /* height: 90vh; */
  height: calc(100vh - 40px);
  /* background-color: rgba(93, 226, 238, 0.4); */
  background: url(/static/media/circuit.84d03d88.jpg) no-repeat center center fixed;
  background-repeat: no-repeat;
  background-position: fixed;
  background-size: cover;
  background-color: rgba(0,0,0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0vw;
}

.scroll-indicator {
  position: absolute;
  bottom: 0px;
  display: inline;
  /* z-index: 0; */
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: linear;
}

@keyframes pulse {
  from {opacity: 1;}
  to {opacity: 0;}
}

h1 {
  font-size: 4em;
}

h3 {
  font-size: 1.4em;
}

a {
  text-decoration: none;

}

.quote {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  background-color: rgba(0,0,0,0.6);
  padding: 20px;
  /* display: none; */
}

.placeholder-text {
  font-size: 1.2em;
  /* display: none; */
}

.project-slideshow {
  position: relative;
  /* height: 100%; */
  height: 51vw;
  width: 100%;
  /* max-width: 600px; */
  display: inline-flex;
  margin: auto;
  z-index: 0;
  background-color: black;
}

.slideshow-title {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  margin: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  transition: 0.3s;
}

.project-slideshow-link {
  width: 600px;
}

.project-slideshow-link:hover .slideshow-title {
  /* font-size: 2.2em; */
  font-size: 1.5em;
  background-color: rgba(0,0,0, 0.8);
}



.demo {
  width: 100%;
}

.slide {
  display: none;
}

.active {
  display: block;
}

.contact-me {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.contact-me svg {
  margin: 10px;
}



@media (min-width: 720px) {
  .home-wrapper {
    margin-top: 4em;
  }

  .home-header {
    height: calc(100vh - 4em);
  }

  h3 {
    font-size: 2em;
  }

  .placeholder-text {
    font-size: 1.8em;
  }

  .project-slideshow {
    height: 340px;
    width: 600px;
  }

  .project-slideshow-link:hover .slideshow-title {
    font-size: 2.2em;
  }

}
.App-main {
  width: 100%;
}

.about-portrait {
  margin: 20px 0px;
}

.about-hero {
  margin: auto;
  padding: 0em 2em;
  /* background: rgba(0,0,0,0.4); */
  /* width: 100%; */
}

.about-hero h1 {
  margin: 0px;
}

.about-hero h2 {
  font-size: 2em;
}

.about-hero h3 {
  font-size: 1.4em;
}

.summary {
  font-size: 1.4em;
  width: 80%;
  margin: auto;
  text-align: left;
}

.section {
  width: 80%;
  border-top: solid;
  border-width: 1px;
  /* margin: 30px 0px 0px; */
  margin: 30px auto 0px;
  text-align: left;
}

.section h3 {
  margin: 0px;
  font-size: 1.4em;
}

.skills, .place, .date {
  margin: 0px;
  /* margin: auto; */
  text-align: left;
}

.link {
  text-decoration: underline;
  display: inline;
}

.resume-download-link {
  padding-top: 20px;
  display: inline-block;
  text-decoration: none;
}

.resume-download-link p {
  text-decoration: underline;
  display: inline-block;
  font-size: 1.4em;
}

@media (min-width: 720px) {
  .about-hero {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .about-hero-text {
    text-align: left;
    padding: 0px 20px;
  }
}
.contact {
  width: 90%;
}

.status-message {
  opacity: 0;
  /* opacity: 1; */
  font-size: 1.2em;
  transition: 0.3s;
  /* margin: 0px; */
}

.success {
  opacity: 1;
  color: rgb(76, 209, 71);
}

.fail {
  opacity: 1;
  color: rgb(235, 37, 37);
}

.contact-form {
  margin: 20px;
}

.contact-form-fields {
  margin: auto;
  width: 100%;
  max-width: 600px;
}

.contact-form-fields input {
  font-family: 'Exo';
  font-size: 1em;
  /* height: 3em; */
  width: 96%;
  max-width: 600px;
  margin: 4px 0px;
  border-radius: 6px;
  display: inline-flex;
}

textarea {
  height: 12em;
  font-family: 'Exo';
  font-size: 1em;
  width: 96%;
  max-width: 600px;
  margin: 4px 0px;
  border-radius: 6px;
  resize: vertical;
}

.chars-left {
  /* width: 10%; */
  /* height: 0px; */
  /* overflow: visible; */
  /* color: black; */
  margin: 0px;
  padding: 0px 4px;
  float: left;
  /* display: inline-flex; */
  /* display: none; */
  /* position: relative; */
  /* bottom: 2em; */
  /* left: 180px; */
}

div[role=alert] {
  color: red;
  margin: 10px 0px 0px 0px;
}

.submit-btn {
  width: 100%;
  max-width: 600px;
  height: 40px;
  margin: 8px 0px;
  color: white;
  font-family: 'Exo';
  font-size: 20px;
  background-color: #0f6e75;
  border-radius: 6px;
  border-style: none;
  cursor: pointer;
  transition: 0.3s;
}

.submit-btn:hover {
  color: black;
  background-color: #4ca09c;
}

/* .submit-btn:active:after {
  transition: 0.3s;
  background-color: #0d575c;
} */

@media (min-width: 720px) {
  .contact-form-fields input {
    font-size: 1.2em;
  }
  textarea {
    font-size: 1.2em;
  }
}
.burger {
  position: fixed;
  top: 4px;
  right: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100000;
}

.burger:focus {
  outline: none;
}

.burger div {
  width: 2rem;
  height: 0.25rem;
  /* background: ${({ theme }) => theme.primaryLight}; */
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.burger-open div {
  background: #0f6e75;
}

.burger-open :first-child {
  transform: rotate(45deg);
}

.burger-open :nth-child(2) {
  opacity: 0;
  transform: translateX(-10px);
}

.burger-open :nth-child(3) {
  transform: rotate(-45deg);
}
.menu {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* background: rgb(21, 64, 75); */
  width: 66vw;
  /* height: 100vh; */
  /* height: 100%; */
  /* height: 400px; */
  /* height: calc(var(--vh, 1vh) * 100); */
  /* min-height: -webkit-fill-available; */
  /* overflow: auto; */
  /* text-align: left; */
  /* padding: 0px 0px 50px; */
  /* padding: 0px; */
  position: fixed;
  top: 0;
  right: 0;
  /* bottom: 0; */
  /* opacity: 0; */
  /* transition: opacity 0.3s; */
  z-index: 1000;
}

.menu > a {
  font-size: 2rem;
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: #0f6e75;
  text-decoration: none;
  transition: color 0.3s linear;
}

.menu > a:hover {
  color: #0f6e75;
}

.menu div.navlinks-container {
  /* opacity: 0; */
  /* transition: opacity 0.3s; */
  transform: translateX(100%);
  transition: transform 0.3s linear;
}

.menu nav.navlink-wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  padding-top: 40px;
  /* margin: 40px 0px 0px; */
  background: rgb(21, 64, 75);
  /* background: grey; */
  /* margin: auto; */
  /* height: 86vh; */
  height: 100vh;
  min-height: -webkit-fill-available;
  /* overflow: auto; */
  /* width: 100%; */
  width: 66vw;
}

.menu a.navlink {
  /* padding: 0px; */
  /* display: flexbox; */
  height: 70px;
  width: 66vw;
  /* border-bottom: solid; */
  /* border-width: 1px; */
  /* border-color: rgb(35, 110, 110); */
}

.media-links-component-container {
  width: 66vw;
  height: 50px;
  display: flex;
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: rgb(21, 64, 75);
  border-top: solid;
  border-width: 1px;
  border-color: rgb(31, 91, 102);
  transform: translateX(100%);
  transition: transform 0.3s linear;
  /* opacity: 0; */
  /* transition: opacity 0.3s; */
  z-index: 1001;
}

.menu div.navlinks-container.menu-open {
  transform: translateX(0);
  /* opacity: 1; */
}

.menu nav.media-icons {
  /* margin: auto; */
}

.menu-open {
  transform: translateX(0);
  /* opacity: 1; */
}
