html {
  /* background: url('./assets/background/wave-bg.jpg') no-repeat center center fixed; */
  /* background-repeat: no-repeat; */
  /* background-position: fixed; */
  /* background-size: cover; */
  background: #182024;
  /* background: linear-gradient(90deg, rgba(160,60,182,1) 0%, rgba(38,77,94,1) 50%, rgba(24,32,36,1) 100%); */
  /* background: linear-gradient(90deg, rgb(71, 23, 82) 0%, rgb(14, 34, 48) 60%, rgba(24,32,36,1) 100%); */



}

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;700&family=Arvo:wght@400;700&family=Exo:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family:
  'Exo',
  /* 'Dosis', */
  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgba(90,60,100, 0.55); */
  /* background-color: rgba(60,50,70, 0.75); */
}

html.noscroll {
  height: 100%;
  overflow: hidden;
  /* overflow-y: auto; */
}

body.noscroll {
  margin: 0px;
  padding: 0px;
  width: 100%;
  /* height: 100%; */
  position: relative;
  /* overflow-y: auto; */
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}