.navbar-wrapper {
  width: 100%;
  background-color: #182024;
  height: 4em;
  border-bottom: solid;
  border-width: 1px;
  border-color: #6FFFE9;
  /* justify-content: center; */
  /* align-items: center; */
  position: fixed;
  z-index: 10;
}

.navbar {
  /* display: inline-block; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* white-space: nowrap; */
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: auto;
  /* position: fixed; */
  top: 0px;
  background-color: #182024;

  /* box-shadow: 0px 0px 10000px #5bc0be */
}

.navbar-left {
  height: 100%;
  display: flex;
  float: left;
  align-items: center;
}

.navbar-name {
  /* margin: auto; */
  display: inline-flex;
  align-items: center;
  margin: 0 12px;
  /* padding: 0 6px; */
}

.navbar-name h1 {
  white-space: nowrap;
  font-family: 'dosis', sans-serif;
  font-size: 40px;
  margin: 0 6px;

}

.navbar-portrait {
  height: 2em;
  border-style: solid;
  border-radius: 2em;
}

.navbar-left a:hover {
  background-color: #0f6e75;
}

.navbar-left a {
  text-decoration: none;
  transition: 0.4s;
}

.navbar-right {
  /* height: 100%; */
  height: 64px;
  /* position: absolute; */
  /* right: 0px; */
  float: right;
  display: flex;
  /* justify-content: center; */
}

.navlink-wrapper {
  margin: auto;
  /* display: flex; */
  width: 480px;
  /* height: 100%; */
  height: 80%;
  z-index: 2;
  /* align-items: center; */
  /* justify-content: center; */
}

.navlink-wrapper a {
  /* width: 50%; */
  /* padding: 12px; */
  /* margin: 0 12px 0px; */
  margin: auto;
  /* width: 120px; */
  width: 25%;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.navlink {
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  /* font-weight: bold; */
  transition: 0.4s;
}

.navlink:hover {
  background-color: #0f6e75;
}

.media-icons {
  white-space: nowrap;
  margin: auto;
  /* display: flex; */
  /* float: right; */
  /* height: 100%; */
}

.media-icons a {
  /* height: 32px; */
  display: inline-block;
  height: 100%;
  /* color: white; */
  text-decoration: none;
  transition: 0.4s;
}

.media-icons a:visited {
  /* color: white; */
}

.media-icons a:hover {
  color: #0f6e75;
}

.media-icons svg {
  margin: 0px 8px;
}

@media (max-width: 950px) {
  .navbar-name h1 {
    font-size: 24px;
  }
  .navlink-wrapper {
    /* display: none; */
    width: 360px;
  }
}

@media (max-width: 720px) {
  .navbar-wrapper {
    height: 40px;
  }
  .navbar-name h1 {
    font-size: 24px;
  }
  .navbar-portrait {
    height: 1.2em;
    border-width: 1px;
  }
  .navbar-right {
    display: none;
  }
}