.App-main {
  width: 100%;
}

.about-portrait {
  margin: 20px 0px;
}

.about-hero {
  margin: auto;
  padding: 0em 2em;
  /* background: rgba(0,0,0,0.4); */
  /* width: 100%; */
}

.about-hero h1 {
  margin: 0px;
}

.about-hero h2 {
  font-size: 2em;
}

.about-hero h3 {
  font-size: 1.4em;
}

.summary {
  font-size: 1.4em;
  width: 80%;
  margin: auto;
  text-align: left;
}

.section {
  width: 80%;
  border-top: solid;
  border-width: 1px;
  /* margin: 30px 0px 0px; */
  margin: 30px auto 0px;
  text-align: left;
}

.section h3 {
  margin: 0px;
  font-size: 1.4em;
}

.skills, .place, .date {
  margin: 0px;
  /* margin: auto; */
  text-align: left;
}

.link {
  text-decoration: underline;
  display: inline;
}

.resume-download-link {
  padding-top: 20px;
  display: inline-block;
  text-decoration: none;
}

.resume-download-link p {
  text-decoration: underline;
  display: inline-block;
  font-size: 1.4em;
}

@media (min-width: 720px) {
  .about-hero {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .about-hero-text {
    text-align: left;
    padding: 0px 20px;
  }
}