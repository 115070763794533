.contact {
  width: 90%;
}

.status-message {
  opacity: 0;
  /* opacity: 1; */
  font-size: 1.2em;
  transition: 0.3s;
  /* margin: 0px; */
}

.success {
  opacity: 1;
  color: rgb(76, 209, 71);
}

.fail {
  opacity: 1;
  color: rgb(235, 37, 37);
}

.contact-form {
  margin: 20px;
}

.contact-form-fields {
  margin: auto;
  width: 100%;
  max-width: 600px;
}

.contact-form-fields input {
  font-family: 'Exo';
  font-size: 1em;
  /* height: 3em; */
  width: 96%;
  max-width: 600px;
  margin: 4px 0px;
  border-radius: 6px;
  display: inline-flex;
}

textarea {
  height: 12em;
  font-family: 'Exo';
  font-size: 1em;
  width: 96%;
  max-width: 600px;
  margin: 4px 0px;
  border-radius: 6px;
  resize: vertical;
}

.chars-left {
  /* width: 10%; */
  /* height: 0px; */
  /* overflow: visible; */
  /* color: black; */
  margin: 0px;
  padding: 0px 4px;
  float: left;
  /* display: inline-flex; */
  /* display: none; */
  /* position: relative; */
  /* bottom: 2em; */
  /* left: 180px; */
}

div[role=alert] {
  color: red;
  margin: 10px 0px 0px 0px;
}

.submit-btn {
  width: 100%;
  max-width: 600px;
  height: 40px;
  margin: 8px 0px;
  color: white;
  font-family: 'Exo';
  font-size: 20px;
  background-color: #0f6e75;
  border-radius: 6px;
  border-style: none;
  cursor: pointer;
  transition: 0.3s;
}

.submit-btn:hover {
  color: black;
  background-color: #4ca09c;
}

/* .submit-btn:active:after {
  transition: 0.3s;
  background-color: #0d575c;
} */

@media (min-width: 720px) {
  .contact-form-fields input {
    font-size: 1.2em;
  }
  textarea {
    font-size: 1.2em;
  }
}