.burger {
  position: fixed;
  top: 4px;
  right: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100000;
}

.burger:focus {
  outline: none;
}

.burger div {
  width: 2rem;
  height: 0.25rem;
  /* background: ${({ theme }) => theme.primaryLight}; */
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.burger-open div {
  background: #0f6e75;
}

.burger-open :first-child {
  transform: rotate(45deg);
}

.burger-open :nth-child(2) {
  opacity: 0;
  transform: translateX(-10px);
}

.burger-open :nth-child(3) {
  transform: rotate(-45deg);
}