.menu {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* background: rgb(21, 64, 75); */
  width: 66vw;
  /* height: 100vh; */
  /* height: 100%; */
  /* height: 400px; */
  /* height: calc(var(--vh, 1vh) * 100); */
  /* min-height: -webkit-fill-available; */
  /* overflow: auto; */
  /* text-align: left; */
  /* padding: 0px 0px 50px; */
  /* padding: 0px; */
  position: fixed;
  top: 0;
  right: 0;
  /* bottom: 0; */
  /* opacity: 0; */
  /* transition: opacity 0.3s; */
  z-index: 1000;
}

.menu > a {
  font-size: 2rem;
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: #0f6e75;
  text-decoration: none;
  transition: color 0.3s linear;
}

.menu > a:hover {
  color: #0f6e75;
}

.menu div.navlinks-container {
  /* opacity: 0; */
  /* transition: opacity 0.3s; */
  transform: translateX(100%);
  transition: transform 0.3s linear;
}

.menu nav.navlink-wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  padding-top: 40px;
  /* margin: 40px 0px 0px; */
  background: rgb(21, 64, 75);
  /* background: grey; */
  /* margin: auto; */
  /* height: 86vh; */
  height: 100vh;
  min-height: -webkit-fill-available;
  /* overflow: auto; */
  /* width: 100%; */
  width: 66vw;
}

.menu a.navlink {
  /* padding: 0px; */
  /* display: flexbox; */
  height: 70px;
  width: 66vw;
  /* border-bottom: solid; */
  /* border-width: 1px; */
  /* border-color: rgb(35, 110, 110); */
}

.media-links-component-container {
  width: 66vw;
  height: 50px;
  display: flex;
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: rgb(21, 64, 75);
  border-top: solid;
  border-width: 1px;
  border-color: rgb(31, 91, 102);
  transform: translateX(100%);
  transition: transform 0.3s linear;
  /* opacity: 0; */
  /* transition: opacity 0.3s; */
  z-index: 1001;
}

.menu div.navlinks-container.menu-open {
  transform: translateX(0);
  /* opacity: 1; */
}

.menu nav.media-icons {
  /* margin: auto; */
}

.menu-open {
  transform: translateX(0);
  /* opacity: 1; */
}