color_palette {
  color1: #0b132b;
  color2: #1c2541;
  color3: #3a506b;
  color4: #5bc0be;
  color5: #6FFFE9;
}

a {
  color: white;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: white;
  width: 100%;
}

.App-main {
  /* padding: 0% 5%; */
  /* width: 90vw; */
  width: 90%;
  max-width: 90%;
  /* padding-top: 60px; */
  /* min-height: 100vh; */
  min-height: calc(100vh - 114px);
  margin: 60px 0px 0px;
}

.App-main-title {
  font-size: 2em;
  margin: 0px;
  
  /* border-bottom: solid; */
  /* border-color: #6FFFE9; */
  /* border-width: 1px; */
}

#overlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0,0,0, 0.7);
  opacity: 0;
  z-index: 100;
  visibility: hidden;
  transition: 0.3s linear;
}

#overlay.noscroll {
  opacity: 1;
  visibility: visible;
}

.project-card {
  background-color: rgba(0,0,0, 0.7);
  /* padding: 30px; */
  margin: 40px 0px;
  /* border-top: solid; */
  /* border-bottom: solid; */
  border-width: 3px;
  border-color: #5bc0be;
  text-align: left;
  display: block;
}

.project-card > div {
  /* display: block; */
  display: inline-block;
  /* margin: 0px 20px; */
}

.project-thumb {
  /* width: 80%; */
  /* flex-basis: 400px; */
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(90vw - (90vw - 100%));

  height: 50.625vw;

  /* margin: auto; */
  
}

.project-thumb img {
  width: 100%;
  height: 100%;
}

.project-description {
  text-align: left;
  padding: 0px 18px;
  /* display: flex; */
  /* width: 200px; */
}

.project-github-link {
  /* display: inline-block; */
}

.project-links p {
  margin: 0px 10px 16px 0px;
  display: inline-block;
  text-decoration: underline;
  transition: 0.4s;
}

.project-links p:hover {
  background-color: #0f6e75;
}

.video-wrapper {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
  overflow:hidden;
  margin: auto;
}

.video-wrapper iframe, .video-wrapper object, .video-wrapper embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.footer {
  font-size: 0.6em;
}




@media (min-width: 1200px) {
  .App-main {
    max-width: 1100px;
  }
}

@media (min-width: 950px) {
  .project-card {
    display: flex;
  }
  .project-thumb {
    width: 420px;
    height: 236px;
  }
}

@media (min-width: 720px) {
  .App-main {
    min-height: calc(100vh - 124px);
  }
  .App-main-title {
    font-size: 3em;
    margin: 0.67em 0em;
  }
  .burger-menu {
    display: none;
  }
  .footer {
    font-size: 0.8em;
  }
}