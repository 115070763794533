.home-wrapper {
  width: 100%;
  /* width: 100vw; */
  /* max-width: 1100px; */
  /* padding: 0px; */
  margin-top: 40px;
}

.App-main.home{
  min-height: 0px;
}

.home {
  margin: auto;
}

.home-header {
  width: 100%;
  /* max-width: 1200px; */
  /* height: 90vh; */
  height: calc(100vh - 40px);
  /* background-color: rgba(93, 226, 238, 0.4); */
  background: url('../assets/background/circuit.jpg') no-repeat center center fixed;
  background-repeat: no-repeat;
  background-position: fixed;
  background-size: cover;
  background-color: rgba(0,0,0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0vw;
}

.scroll-indicator {
  position: absolute;
  bottom: 0px;
  display: inline;
  /* z-index: 0; */
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: linear;
}

@keyframes pulse {
  from {opacity: 1;}
  to {opacity: 0;}
}

h1 {
  font-size: 4em;
}

h3 {
  font-size: 1.4em;
}

a {
  text-decoration: none;

}

.quote {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  background-color: rgba(0,0,0,0.6);
  padding: 20px;
  /* display: none; */
}

.placeholder-text {
  font-size: 1.2em;
  /* display: none; */
}

.project-slideshow {
  position: relative;
  /* height: 100%; */
  height: 51vw;
  width: 100%;
  /* max-width: 600px; */
  display: inline-flex;
  margin: auto;
  z-index: 0;
  background-color: black;
}

.slideshow-title {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  margin: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  transition: 0.3s;
}

.project-slideshow-link {
  width: 600px;
}

.project-slideshow-link:hover .slideshow-title {
  /* font-size: 2.2em; */
  font-size: 1.5em;
  background-color: rgba(0,0,0, 0.8);
}



.demo {
  width: 100%;
}

.slide {
  display: none;
}

.active {
  display: block;
}

.contact-me {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.contact-me svg {
  margin: 10px;
}



@media (min-width: 720px) {
  .home-wrapper {
    margin-top: 4em;
  }

  .home-header {
    height: calc(100vh - 4em);
  }

  h3 {
    font-size: 2em;
  }

  .placeholder-text {
    font-size: 1.8em;
  }

  .project-slideshow {
    height: 340px;
    width: 600px;
  }

  .project-slideshow-link:hover .slideshow-title {
    font-size: 2.2em;
  }

}